.clip-loader {
  .spinner {
    animation: rotate 2s linear infinite;
    height: 25px;
    width: 25px;
    z-index: 2;

    & .path {
      animation: dash 1.5s ease-in-out infinite;
      stroke-linecap: round;
      stroke: $blue1;
    }
  }
}
