.navigation {
  user-select: none !important;
  -webkit-user-select: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  display: flex;
  align-items: flex-end;
  position: fixed;
  width: 100%;
  z-index: 5;
  min-height: 65px;
  background: $white;
  padding: 0 10px 10px 0;
  .slide-in {
    display: flex;
    align-items: flex-end;
    width: 100%;
  }

  .slide-in-settings {
    display: flex;
    align-items: flex-end;
    width: 100%;
  }

  .left,
  .right {
    flex: 1;
  }

  .right {
    display: flex;
    justify-content: flex-end;
    .click {
      padding: 6px 6px 2px 6px;
    }
  }

  .back {
    color: #0098ce;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 15px;
    font-weight: 400;
    padding: 3px 0 3px 10px;
    max-width: 70px;
    img {
      padding-right: 8px;
    }
  }
  background: -moz-linear-gradient(
    top,
    #fff 0%,
    #f7f7f7 50%,
    #fff 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    #fff 0%,
    #f7f7f7 50%,
    #fff 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    #fff 0%,
    #f7f7f7 50%,
    #fff 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */

  /* Status bar height on iOS 10 */
  padding-top: 20px;
  /* Status bar height on iOS 11.0 */
  padding-top: constant(safe-area-inset-top);
  /* Status bar height on iOS 11+ */
  padding-top: env(safe-area-inset-top);
  a {
    font-family: "Source Sans Pro", sans-serif;
    font-size: 1rem;
    font-weight: 400;
    color: $blue1;
    &:link,
    &:visited,
    &:hover,
    &:active {
      text-decoration: none;
    }
  }

  @media (prefers-color-scheme: dark) {
    background-color: $grey6-dark;
    background: $grey6-dark;
    border-bottom: 1px solid $grey7-dark;
  }
  .info-help {
    max-width: 40px;
  }
}

.ios {
  .navigation {
    border-bottom: 1px solid rgba(0, 0, 0, 0.28);
    .right {
      .click {
        padding: 6px 15px 6px 6px;
      }
    }

    .back {
      font-size: 17px;
      max-width: 75px;
      padding: 6px 0 0 10px;
    }
    padding: 0 0 7px 0;
    @media only screen and (min-width: 376px) {
      min-height: 88px;
    }
    //Xs
    @media only screen and (device-width: 375px) and (device-height: 812px) {
      min-height: 88px;
    }
    //6s, 7
    @media only screen and (device-width: 375px) and (device-height: 667px) {
      min-height: 68px;
    }
    // 7 plus, 6s plus
    @media only screen and (device-width: 414px) and (device-height: 736px) {
      min-height: 68px;
    }
    .info-help {
      max-width: 40px;
      img {
        margin-bottom: .5em;
      }
    }
  }
}

.android {
  .navigation {
    min-height: 3.8em;
    padding: 4px 1em 0 1em;
    align-items: center;
    .wrapper {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
    }

    .wrapper-edit {
      display: flex;
      align-items: center;
    }

    .back {
      margin-right: 1.2em;
      padding: 0 0.5em 0 0.5em;
      display: flex;
    }
    .info-help {
      align-items: center;
      display: flex;
      justify-content: center;
      margin-left: 1.5em;
      padding: 0 0.5em 0 0.5em;
    }
    justify-content: flex-start;
  }
  .showAnimate {
    #slider-nav {
      transform: translateX(-100%);
      -webkit-transform: translateX(-100%);
    }

    #slider-nav-settings {
      transform: translateX(100%);
      -webkit-transform: translateX(100%);
    }

    .slide-in-settings {
      animation: slide-in-settings 0.25s forwards;
      -webkit-animation: slide-in-settings 0.25s forwards;
    }

    .slide-in {
      animation: slide-in 0.25s forwards;
      -webkit-animation: slide-in 0.25s forwards;
    }

    .slide-out {
      animation: slide-out 0.25s forwards;
      -webkit-animation: slide-out 0.25s forwards;
    }

    @keyframes slide-in {
      100% {
        transform: translateX(0%);
      }
    }

    @-webkit-keyframes slide-in {
      100% {
        -webkit-transform: translateX(0%);
      }
    }

    @keyframes slide-in-settings {
      100% {
        transform: translateX(0%);
      }
    }

    @-webkit-keyframes slide-in-settings {
      100% {
        -webkit-transform: translateX(0%);
      }
    }

    @keyframes slide-out {
      0% {
        transform: translateX(0%);
      }

      100% {
        transform: translateX(-100%);
      }
    }

    @-webkit-keyframes slide-out {
      0% {
        -webkit-transform: translateX(0%);
      }

      100% {
        -webkit-transform: translateX(-100%);
      }
    }
  }
}
