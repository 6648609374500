html,
body {
  background-color: $grey2;
  color: $grey1;
  font-family: "RBNo2.1a", sans-serif;
  font-size: 1rem;
  height: 100%;
  margin: 0;
  overflow: hidden;
  padding: 0;
  width: 100%;

  .react-loading {
    background-color: $grey6;
    height: 100vh;
    @media (prefers-color-scheme: dark) {
      background-color: $grey6-dark;
    }

    .spinner {
      animation: rotate 2s linear infinite;
      height: 100px;
      left: 50%;
      margin: -50px 0 0 -50px;
      position: absolute;
      top: 50%;
      width: 100px;
      z-index: 2;

      & .path {
        animation: dash 1.5s ease-in-out infinite;
        stroke: $blue1;
        stroke-linecap: round;
      }

    }
    @keyframes rotate {
      100% {
        transform: rotate(360deg);
      }
    }

    @keyframes dash {
      0% {
        stroke-dasharray: 1, 150;
        stroke-dashoffset: 0;
      }

      50% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -35;
      }

      100% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -124;
      }
    }

  }
}

.app {
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  display: flex;
  flex-direction: column;
  height: 100%;
  user-select: none;
}

.app-wrapper,
.right,
.left {
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
}

.content {
  background-color: #f4f5f5; // $grey6;
  display: flex;
  height: 100%;
  overflow-x: visible;
  padding-top: 65px;
  text-align: center;
  width: 100%;

  .content-scroll {
    -webkit-overflow-scrolling: touch; // ios scrollbar fix
    background-color: transparent;
    overflow: auto;
    width: 100%;

    .ptr .clip-loader {
      margin-top: 15px;
    }
  }
}

@media (prefers-color-scheme: dark) {
  html,
  body,
  h1 {
    background: $grey6-dark;
    background-color: $grey6-dark;
    color: $grey1-dark;
  }

  .content {
    background: $white-dark;
    background-color: $white-dark;
  }

  .workout-icon {
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg)
      brightness(102%) contrast(102%);
  }
}

.ios {
  .content {
    padding-bottom: calc(var(--nav-height) - 8px);
    padding-top: var(--nav-height);
    // 7 plus, 6s plus
    @media only screen and (device-width: 414px) and (device-height: 736px) {
      padding-bottom: calc(var(--nav-height) - 21px);
    }
    //6s, 7
    @media only screen and (device-width: 375px) and (device-height: 667px) {
      padding-bottom: calc(var(--nav-height) - 21px);
    }
    // SE
    @media only screen and (device-width: 320px) and (device-height: 568px) {
      padding-bottom: calc(var(--nav-height) - 21px);
    }
  }
}

.android {
  .content {
    padding-top: var(--nav-height)
  }
}
