$primary-blue: #00a9e8;

$blue1: #0098ce;
$blue2: #007faa;
$grey1: #474a4a;
$grey2: #77777a;
$grey3: #bcbcbc;
$grey4: #e7e6e7;
$grey5: #f6f6f6;
$grey6: #e5e5e5;
$grey7: #f2f3f3;
$white: #ffffff;
$error: #d50000;
$green1: #329c45;
$orange1: #df8635;
$black-opaque: rgba(0, 0, 0, 0.5);
$black-legend: rgba(0, 32, 32, 0.87);

$blue1-dark: #51ccf8;
$grey1-dark: #ffffff;
$grey2-dark: #bcbcbc;
$grey3-dark: #474a4a;
$grey4-dark: #474a4a;
$grey5-dark: #0e0e0e;
$grey6-dark: #1e1e1e;
$grey7-dark: rgba(51, 51, 51, 1);
$white-dark: #131313;
$error-dark: #ff3838;
