.page-transition-group {
  position: relative;
  width: 100%;
  height: 100%;
  perspective: 1200px;
  overflow: hidden;
  .page-transition-wrapper {
    backface-visibility: hidden;
    height: 100%;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    transform-style: preserve-3d;
    transform: translate3d(0, 0, 0);
    width: 100%;
    will-change: transform;
  }
}
