.sleep-zones-help {
  margin: 24px 16px;

  .sleep-zone-description {
    svg {
      margin-right: 8px;
    }
  }

  .sleep-zone-description:last-child {
    margin-bottom: 0;
  }
}

.statistics-container {
  .card-body {
    font-family: "Inter", sans-serif;
    ::-webkit-scrollbar {
      display: none;
    }

    .goal-indicators {
      height: 0;
      position: relative;
    }

    .dashed-line {
      border-bottom: 1px dashed;
      width: 100%;
    }

    .target-metric-wrapper {
      display: flex;
      justify-content: flex-end;
      width: 100%;
    }

    .target-metric {
      background-color: #1e1e1e;
      border-radius: 7px;
      bottom: 22px;
      color: $white;
      font-size: 12px;
      font-weight: 400;
      letter-spacing: 0.1px;
      line-height: 18px;
      opacity: 0.9;
      padding: 0 4px 0 4px;
      position: relative;
      right: 5%;
      width: fit-content;
      z-index: 5;
      @media (prefers-color-scheme: dark) {
        background-color: $white;
        color: $grey3-dark;
      }
    }

    .loading-spinner {
      bottom: 85px;
      left: 2%;
      position: absolute;
    }

    .scroll-container {
      direction: rtl;
      overflow-x: scroll;
      scroll-snap-type: x mandatory;
      .offset {
        height: 108px;
        margin-right: 50%;
        padding-left: 3355442px;
        position: relative;
      }

      .graph-item-container {
        bottom: 0;
        position: absolute;
        .graph-item-overlay {
          height: 100%;
          transform: rotate(180deg);
        }

        .in-workout {
          border-radius: 4px;
        }
      }
    }

    .legend-container {
      align-items: center;
      display: flex;
      font-size: 11px;
      font-weight: 400;
      justify-content: space-between;
      padding: 0.5em 1.5em;
      text-transform: capitalize;
      width: fit-content;
      .legend-text {
        padding: 0 13px;
      }

      .legend {
        border-radius: 5px;
        height: 8px;
        margin-top: 1px;
        width: 17px;
      }

      .in-workout-normal {
        background-color: #a15700;
        background-image: -webkit-linear-gradient(
          114deg,
          #a15700 50%,
          #006310 50%
        );
      }

      .in-workout-pass {
        background-color: #006310;
      }

      .in-workout-fail {
        background-color: #a15700;
      }

      .total-normal {
        background-color: #de9332;
        background-image: -webkit-linear-gradient(
          114deg,
          #de9332 50%,
          #329c45 50%
        );
      }

      .total-pass {
        background-color: #329c45;
      }

      .total-fail {
        background-color: #de9332;
      }
    }

    .help-text {
      font-size: 11px;
      font-weight: 400;
      line-height: 24px;
    }

    .vertical-rule {
      border-right: 2px solid #0e0e0e;
      height: 22px;
      @media (prefers-color-scheme: dark) {
        border-right: 1px solid $white;
      }
    }

    .graph-item {
      border-radius: 4px;
      cursor: pointer;
      display: inline-block;
      margin: 2px 6px;
      text-align: center;
      width: 8px;
    }

    .snap-align-center {
      scroll-snap-align: center;
    }

    .date {
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.15px;
      line-height: 20px;
      padding-bottom: 1px;
      text-transform: capitalize;
    }

    .card-top-info {
      padding-top: 6px;
      text-align: center;
      .data {
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        padding-bottom: 7px;
      }
    }

    .card-top-info-date {
      display: flex;
      font-size: 14px;
      font-weight: 400;
      justify-content: space-between;
      letter-spacing: 0.15px;
      line-height: 20px;
      margin-bottom: -1px;
      padding: 0 1em;
      width: 100%;
    }

    .card-top-info-button {
      min-width: 28px;
      padding: 0 10px;
    }

    .data-wrapper {
      align-items: center;
      display: flex;
      height: 20px;
      justify-content: center;
      width: 100%;
      .data-description-left {
        align-items: flex-end;
        display: flex;
        justify-content: flex-end;
        padding-right: 5px;
        width: 50%;
      }

      .data-description-right {
        align-items: flex-end;
        display: flex;
        justify-content: flex-start;
        padding-left: 5px;
        width: 50%;
      }

      .count {
        font-size: 16px;
        font-weight: 600;
        line-height: 26px;
        min-width: 10px;
        padding-right: 5px;
      }
    }

    .weekly-total {
      border-bottom: 1px solid rgba(0, 0, 0, 0.08);
      font-size: 1em;
      font-weight: 600;
      padding-bottom: 0.7em;
      padding-top: 1px;
      @media (prefers-color-scheme: dark) {
        border-bottom: 1px solid $grey7-dark;
      }
    }

    .workout-info {
      font-weight: 500;
      padding-left: 1em;
      text-align: left;
      .info-item {
        display: flex;
        justify-content: space-between;
        padding: 0.9em 1em 0.9em 0;
      }

      .workouts,
      .distance {
        border-bottom: 1px solid rgba(0, 0, 0, 0.08);
        @media (prefers-color-scheme: dark) {
          border-bottom: 1px solid $grey7-dark;
        }
      }

      .ascent {
        border-top: 1px solid rgba(0, 0, 0, 0.08);
        @media (prefers-color-scheme: dark) {
          border-top: 1px solid $grey7-dark;
        }
      }

      .metric {
        font-weight: 400;
      }
    }
    .card-header-toggle {
      display: flex;
      font-weight: 500;
      justify-content: center;
      .tab-wrapper {
        background-color: $grey4;
        border-radius: 4px;
        display: flex;
        padding: 0.25em;
        @media (prefers-color-scheme: dark) {
          background-color: $grey4-dark;
        }
        .card-header-toggle-button {
          border-radius: 4px;
          padding: 0.4em;
        }
        .active {
          background-color: $white;
          @media (prefers-color-scheme: dark) {
            background-color: $grey6-dark;
          }
        }
      }
    }
    .empty-description {
      color: $grey1;
      font-family: "Inter", sans-serif;
      font-size: 14px;
    }
  }

  .workout-card {

    .workout-content {
      display: flex;
      justify-content: center;
      min-height: 130px;
      .offset {
        min-height: 100px;
      }

      .weekday-container {
        bottom: -12px;
        display: flex;
        position: absolute;
        .weekday {
          font-size: 14px;
          width: 20px;
        }
      }
    }

    .workout-content-loading {
      align-items: flex-end;
      display: flex;
      justify-content: center;
      margin-right: 40px;
      min-height: 130px;
      padding-bottom: 20px;
    }
  }

  .heart-rate-card {
    .hr-content {
      all: initial !important;
    }

    .swiper-wrapper {
      .swiper {
        overflow: hidden;
      }

      .swiper-inner {
        transition: transform 0.3s;
        white-space: nowrap;
      }

      .swiper-item {
        min-height: 230px;
        width: 100%;
        .graph-loading {
          align-items: center;
          display: inline-flex;
          justify-content: center;
          min-height: 230px;
          min-width: 100%;
        }

        .chart-container {
          -webkit-user-select: none;
          margin-left: 1em;
          min-height: 230px;
          user-select: none !important;
          #heart-rate-chart {
            max-width: 90vw;
            padding-bottom: 1em;
            padding-top: 1.5em;
          }
        }
      }
      .empty-heart-rate-chart {
        align-items: center;
        display: flex;
        flex-direction: column;
        height: 230px;
        justify-content: center;
        max-width: 100vw;
        white-space: normal;
        text-align: center;
      }
    }
  }

  .odometer-card {
    .workout-family {
      align-items: center;
      display: flex;
      font-size: 0.8rem;
      justify-content: space-between;
      margin: 0.5em 1em;
      .family-title {
        img {
          margin-right: 0.5em;
          padding-left: 0.5em;
        }
        font-size: 1rem;
        font-weight: 500;
      }

      .family-metric {
        font-weight: 600;
        img {
          margin-left: 0.5em;
          padding-right: 0.5em;
        }
      }
    }
  }

  .odometer-card.card-body {
    padding: 0;
    flex: none;
  }
  .sleep-card.card-body {
    padding-bottom: 0;
  }

  .no-display-items {
    padding: 1em 0;
  }
  .sleep-card {
    .clip-loader {
      .spinner {
        height: 60px;
        width: 60px;
      }
    }
    .sleep-session-chart {
      .loading-container {
        align-items: flex-start;
        display: flex;
        justify-content: center;
        padding-bottom: 1em;
        width: 100%;
      }
    }
    .card-top-info-date {
      padding-top: 1em;
    }
    .daily-sleep-card {
      .daily-sleep-chart-container {
        align-items: center;
        display: flex;
        justify-content: center;
        min-height: 300px;
        overflow: hidden;
      }
      path[stroke-width="6.5"] {
        stroke: transparent;
      }
      ellipse[stroke-width="6.5"] {
        stroke: transparent;
      }
      .google-visualization-tooltip {
        background-color: transparent;
        border: none;
        box-shadow: none;
        font-size: 11px;
        height: 0;
      }
      .donutCell
      {
        position: relative;
      }

      .center-label {
        font-size: .9em;
        align-items: center;
        display: flex;
        flex-direction: column;
        height: 51%;
        justify-content: center;
        left: 35%;
        position: absolute;
        top: 24%;
        width: 30%;
        .goal-icon {
          padding-bottom: 3px;
        }
        .goal-text {
          font-size: 11px;
        }
        .goal-value {
          font-size: 25px;
        }
      }
    }
    .weekly-sleep-chart-container {
      margin-left: 3%;
      min-height: 256px;
      overflow:hidden;
      position: relative;
      #weekly-sleep-chart {
        height:100%;
        padding: .5em 0;
        width:100%;
        rect {
          stroke-dasharray: 10 5;
        }
      }
      .sleep-score-label-container {
        bottom: 12px;
        display: flex;
        justify-content: flex-end;
        width: 100%;
        padding: 0 1.7em;
        position: absolute;
        .sleep-score-label {
          background-color: $black-legend;
          border-radius: 7px;
          color: $white;
          font-size: 11px;
          padding: .2em 1.25em;
          text-transform: lowercase;
          @media (prefers-color-scheme: dark) {
            background-color: $grey3;
            color: $white-dark;
          }
        }
      }
      .sleep-goal-indicator {
        background-color: $grey5-dark;
        border-radius: 7px;
        color: $white;
        font-size: 12px;
        padding: 0 .5em;
        position: absolute;
        right: 8%;
        top: calc(var(--sleep-goal-height) - 14px);
        @media (prefers-color-scheme: dark) {
          background-color: $white;
          color: $white-dark;
        }
      }
    }
    .sleep-info-row {
      align-items: center;
      border-top: 1px solid $grey4;
      display: flex;
      justify-content: space-between;
      min-height: 54px;
      padding: .3em 1em;
      select:focus {
        outline-color: #0099BD;
      }
      select {
        color: #000000;
      }
      .info-row-input {
        border-radius: 4px;
        border: 1px solid $grey3;
        height: 43px;
        text-align: right;
        width: 100px;
        padding: 0 11px;
        -webkit-appearance: none;
        @media (prefers-color-scheme: dark) {
          background-color: $grey4-dark;
          border: none;
          color: $white;
        }
      }
      .date-input {
        width: 110px;
        padding: 0 5px;
      }
      .sleep-info-toggle {
        align-items: center;
        display: flex;
        height: 30px;
        justify-content: center;
        margin-left: 5px;
        width: 30px;
      }
    }
    .how-sleep {
      min-height: 62px;
    }
    .sleep-session-container {
      border-top: 1px solid $grey4;
      padding-bottom: 1em;
      cursor: pointer;
      .sleep-session-header {
        padding: 1em 1em 0 1em;
      }
      .sleep-session-information {
        display: flex;
        font-family: "Inter", sans-serif;
        padding-top: 1em;
        width: 100%;
        .first {
          display: flex;
          justify-content: flex-end;
          padding-right: 4px;
          padding-top: 2px;
          width: 10%;
        }
        .second {
          width: 75%;
        }
        .third {
          padding-left: 1em;
          width: 10%;
        }
        .sleep-duration {
          font-size: 1em;
          font-weight: 500;
        }
        .sleep-time {
          font-size: .7em;
        }
        .sleep-time-small {
          display: flex;
          justify-content: space-between;
          margin-top: -4px
        }
      }
      .sleep-session-chart-container {
        display: flex;
        padding: .5em 1em 0;
        .sleep-session-chart {
          align-items: flex-end;
          display: flex;
          width: 100%;
        }
      }
      .sleep-session-time-marks {
        font-family: "Inter", sans-serif;
        font-size: 10px;
        padding: 0 .5em;
      }
      .sleep-dot {
        background-color: $grey3;
        border-radius: 50%;
        display: inline-block;
        height: 4px;
        width: 4px;
      }
    }
  }
  .hrv-card {
    .card-top-info {
      .hrv-reading {
        align-items: center;
        border-radius: 4px;
        border: 1px solid $grey3;
        display: flex;
        height: 44px;
        justify-content: center;
        margin: .1em 0;
        width: 100px;
      }
    }
    .card-header-toggle {
      padding-bottom: 1em;
    }
    .chart-scroll {
      overflow-x: scroll;
      .hrv-chart-wrapper{
        width: 200vw;
        display: flex;
        justify-content: center;
        padding-right: 10vw;
        padding-left: 10vw;
      }
    }
    .hrv-chart-container {
      min-height: 110px;
      #hrv-label {
        top: 173px;
        left: 0;
        max-width: 50px;
        pointer-events: none;
        max-height: 110px;
      }
    }
    .hrv-info-row {
      align-items: center;
      border-top: 1px solid $grey4;
      display: flex;
      justify-content: space-between;
      min-height: 54px;
      padding: .3em 1em;
    }
    .no-data {
      align-items: center;
      display: flex;
      justify-content: center;
      min-height: 88px;
      padding: 0 3em;
    }
    .hrv-info-link {
      display: flex;
      height: 0;
      justify-content: flex-end;
      width: 94%;
      .link-text {
        bottom: 41px;
        color: #0098ce;
        font-family: "Source Sans Pro", sans-serif;
        font-size: 14px;
        height: 26px;
        padding: 0 .5em;
        position: relative;
      }
    }
    .clip-loader {
      .spinner {
        height: 60px;
        width: 60px;
      }
    }
  }
  .rhr-card {
    .rhr-chart-container {
      min-height: 60px;
      .offset {
        height: 80px;
      }
    }
  }
}

.ios-scroll-fix {
  .workout-card {
    .scroll-container {
      width: 100%;
      .offset {
        height: 105px;
        margin-right: 50%;
        padding-left: 320px;
        position: relative;
      }
    }
  }
}

.ios {
  // ios safari doesn't respect direction: rtl and snap scroll on the same container
  // however, if you turn it upside down once, and then again, it works fine
  .scroll-container {
    transform: rotateY(180deg);
  }

  .graph-item-container {
    transform: rotateY(-180deg);
  }

  .weekday-container {
    transform: rotateY(-180deg);
  }

  .statistics-container .sleep-card .sleep-info-row select {
    direction: rtl;
  }
}