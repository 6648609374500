.card {
  background-color: transparent;
  border: 0;
  border-radius: 0;
  width: 100%;
  .card-header {
    background-color: transparent;
    border: 0;
    border-bottom: 0;
    border-radius: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 15px;
    text-transform: capitalize;
    .card-title {
      display: flex;
      margin-bottom: 0 !important;
    }
      .header-icon {
        margin-right: 12px;
      }
      .workout-icon {
        filter: invert(31%) saturate(400%) hue-rotate(47deg) grayscale(60%);
        @media (prefers-color-scheme: dark) {
          filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg)
          brightness(102%) contrast(102%);
        }
      }

    h2, h3 {
      font-size: 20px;
      font-weight: 600;
      letter-spacing: -0.5px;
      line-height: 28px;
      padding-top: 0;
      margin-bottom: 0;
    }

    .card-right {
      display: flex;
      align-items: center;

      .beta {
        display: inline-block;
        background-color: $white;
        letter-spacing: 1px;
        padding: 0 16px;
        border-radius: 10px;
        margin-right: 8px;
      }
    }
  }

  .card-body {
    background-color: #fff;
    border: 0;
    border-radius: 0;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    outline: none;
    padding: 8px 0 10px 0;
  }

  .calories-card,
  .steps-card {
    .arrow-line {
      padding-top: 6px;
    }
  }
}

.ios {
  .card {
    -webkit-transform: translateZ(0); // ios scrollbar fix
  }
}

@media (prefers-color-scheme: dark) {
  .card {
    .card-header {
      background-color: $white-dark;

      .card-right {
        .beta {
          color: $grey6-dark;
        }
      }
    }

    .card-body {
      background-color: $grey6-dark;
      box-shadow: 0 1px 3px $grey7-dark;
    }
  }
}
