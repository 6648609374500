.ReactModalPortal {
  .info-modal {
    background: $white;
    border-radius: 8px 8px 0 0;
    border: none;
    display: flex;
    flex-direction: column;
    font-family: "Inter", sans-serif;
    height: fit-content;
    max-height: 89vh;
    outline: none;
    width: 100%;
    @media (prefers-color-scheme: dark) {
      background: $grey6-dark;
    }
  }

  .info-modal-overlay {
    align-items: flex-end;
    background-color: $black-opaque;
    display: flex;
    inset: 0;
    position: fixed;
    z-index: 10;
  }
  .info-modal-header {
    align-items: center;
    border-bottom: 1px solid $grey4;
    display: flex;
    font-size: 17px;
    font-weight: 600;
    justify-content: space-between;
    padding: .5em 1.25em;
    width: 100%;
    .info-modal-close {
      padding: .5em;
    }
  }
  .info-modal-content {
    display: flex;
    overflow-y: scroll;
    width: 100%;
  }
  .info-modal-dismiss {
    border-top: 1px solid $grey4;
    display: flex;
    justify-content: flex-end;
    padding: .75em 1em;
    width: 100%;
  }
  .info-modal-confirm-btn {
    background-color: $blue2;
    border-radius: 4px;
    border: none;
    color: $white;
    min-width: 80px;
    outline: none;
    padding: .5em .75em;
    text-transform: capitalize;
  }
  .info-modal-custom-actions {
    border-top: 1px solid $grey4;
    padding: .75em 1.25em;
    width: 100%;
  }
  .info-modal-cancel-btn {
    color: $blue2;
    font-weight: 500;
  }
  .edit-row {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: .5em 1.25em;
    width: 100%;
    .goal-metric {
      border-radius: 4px;
      border: 1px solid $grey3;
      height: 44px;
      text-align: right;
      padding: 0 12px;
    }
  }
  .edit-row:nth-child(1){
    border-bottom: 1px solid $grey4;
  }
}
.ios {
  .info-modal-overlay {
    height: 100%;
    bottom: 0;
    left: 0;
  }
  .info-modal {
    margin-bottom: calc(var(--nav-height) - 8px);
  }
}
