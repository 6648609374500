.odometer-card {
  .collapse-item {
    .extra-right {
      padding-right: 2.6em;
    }
  }

  .collapse-item-header {
    display: flex;
    padding: 1em 1.2em 1em 1.2em;
    .collapse-item-header-content {
      align-items: flex-end;
      display: flex;
      justify-content: space-between;
      width: 100%;
      .title {
        display: flex;
        justify-content: flex-end;
        .icon {
          margin-right: 11px;
          width: 20px;
          img {
            object-fit: contain;
          }
        }

        .text {
          font-size: 1em;
          font-weight: 500;
          top: 1px;
        }
      }

      .total-distance {
        display: flex;
        font-size: 14px;
        font-weight: 600;
        .expand-arrow {
          margin-left: 1em;
          img {
            margin-bottom: 3px;
            transform: rotate(90deg);
          }
        }

        .open {
          img {
            transform: rotate(-90deg);
          }
        }
      }
    }
  }

  .collapse-item-panel {
    .family-items {
      font-size: 14px;
      padding: 0 1em 1.5em;
      .family-item {
        display: flex;
        justify-content: space-between;
        padding: 0.5em 2em 0.5em 0.75em;
      }

      .family-item:nth-child(odd) {
        background-color: #f4f5f5;
        @media (prefers-color-scheme: dark) {
          background-color: $white-dark;
        }
      }
    }
  }

  .collapse-item:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    @media (prefers-color-scheme: dark) {
      border-bottom: 1px solid $grey7-dark;
    }
  }

  .ReactCollapse--collapse {
    transition: height 400ms ease-in-out;
  }
}
