.sortable-container {
  user-select: none !important;
  padding-top: 12px;
  padding-bottom: 12px;
}
.sortable-handle {
  padding: 3px 10px;
}
.sortable-helper {
  img {
    height: 15px;
  }
}
.ios {
  .sortable-helper {
    box-shadow: 0px 2px 11px 0px rgba(0, 0, 0, 0.4);
    border-radius: 1px;
    border-top: 1px solid #cdcdcd;
    border-bottom: 1px solid #cdcdcd;
    opacity: 0.8;
  }
}
@media (prefers-color-scheme: dark) {
  .ios {
    .sortable-helper {
      box-shadow: 0px 2px 11px 0px rgba(0, 0, 0, 0.4);
      border-radius: 1px;
      border-top: 1px solid $grey1;
      border-bottom: 1px solid $grey1;
    }
  }
}
