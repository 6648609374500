.network-status {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: $grey5;
  border: 1px solid $error;
  border-radius: 0;
  display: flex;
  text-align: center;
  color: $error;
  font-size: 14px;
  line-height: 20px;
  z-index: 10;
}
