//*** RBNo3.1 ***//

@font-face{font-family:'RBNo3.1'; // -Light
  src:url('../../fonts/RBNo3.1-Light.otf') format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face{font-family:'RBNo3.1'; // -Book
  src:url('../../fonts/RBNo3.1-Book.otf') format("truetype");
  font-weight: 300;
  font-style:normal;
}

@font-face{
  font-family:'RBNo3.1'; // -Medium
  src:url('../../fonts/RBNo3.1-Medium.otf') format("truetype");
  font-weight: 400;
  font-style:normal;
}


@font-face{font-family:'RBNo3.1'; // -Bold
  src:url('../../fonts/RBNo3.1-Bold.otf') format("truetype");
  font-weight: 500;
  font-style:normal;
}


@font-face{font-family:'RBNo3.1'; // -Extrabold
  src:url('../../fonts/RBNo3.1-Extrabold.otf') format("truetype");
  font-weight: 600;
  font-style:normal;
}

@font-face{font-family:'RBNo3.1'; // -Black
  src:url('../../fonts/RBNo3.1-Black.otf') format("truetype");
  font-weight: 700;
  font-style:normal;
}

//*** RBNo2.1a ***//

@font-face{font-family:'RBNo2.1a'; // -Book
  src:url('../../fonts/rbno2_1a-book-webfont.svg') format("svg"),
  url('../../fonts/rbno2_1a-book-webfont.woff') format("woff"),
  url('../../fonts/rbno2_1a-book-webfont.eot') format("eot"),
  url('../../fonts/rbno2_1a-book-webfont.ttf') format("truetype");
  font-weight:400;
  font-style:normal;
}

@font-face{font-family:'RBNo2.1a'; // -Medium
  src:url('../../fonts/rbno2_1a-medium-webfont.svg') format("svg"),
  url('../../fonts/rbno2_1a-medium-webfont.woff') format("woff"),
  url('../../fonts/rbno2_1a-medium-webfont.eot') format("eot"),
  url('../../fonts/rbno2_1a-medium-webfont.ttf') format("truetype");
  font-weight:500;
  font-style:normal;
}

@font-face{font-family:'RBNo2.1a'; // -Bold
  src:url('../../fonts/rbno2.1a-bold-webfont.svg') format("svg"),
  url('../../fonts/rbno2.1a-bold-webfont.woff') format("woff"),
  url('../../fonts/rbno2.1a-bold-webfont.eot') format("eot"),
  url('../../fonts/rbno2.1a-bold-webfont.ttf') format("truetype");
  font-weight:700;
  font-style:normal;
}

//*** Antonio ***//

@font-face {
  font-family: 'Antonio Light';
  font-style: normal;
  font-weight: normal;
  src: local('Antonio Light'), url('../../fonts/Antonio-Light.woff') format('woff');
  src: local('Antonio Light'), url('../../fonts/Antonio-Light.woff') format('woff');
}


@font-face {
  font-family: 'Antonio Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Antonio Regular'), url('../../fonts/Antonio-Regular.woff') format('woff');
}


@font-face {
  font-family: 'Antonio Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Antonio Bold'), url('../../fonts/Antonio-Bold.woff') format('woff');
}
