h1 {
  text-transform: uppercase;
  font-size: 1.55rem;
  font-weight: 700;
  margin: 0;
  padding: 0;
  letter-spacing: 0.1px;
}
.ios {
  h1 {
    font-size: 27px;
  }
}
h2 {
  font-family: "Inter", sans-serif;
  font-size: 1.2rem;
  font-weight: 600;
  margin: 0;
  padding-top: 5px;
}
