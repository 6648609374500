.not-logged-in {
  align-items: flex-start;
  background-color: $grey5;
  display: flex;
  height: 100%;
  justify-content: center;
  padding-top: 5%;
  .notification-container {
    align-items: center;
    background-color: $white;
    border-radius: 1px;
    border: 1px solid $grey3;
    display: flex;
    font-size: 1.5em;
    justify-content: center;
    max-width: 400px;
    padding: 1em;
    width: 80%;
  }
  .notification-text {
    color: #474a4a;
    font-size: 30px;
    font-weight: 600;
    text-transform: uppercase;
  }
}
.header-section{
  background: #FFF;
  border-bottom: 1px solid #e7e6e7;
  width: 100%;
  .header-container{
    .logo{
      height: 28px;
      margin: 19px 10px;
    }
  }
}