.settings-item {
  -webkit-user-select: none;
  background-color: $white;
  font-family: "Inter", sans-serif;
  font-size: 0.85rem;
  margin-bottom: 12px;
  padding: 1em 1.2em;
  user-select: none !important;
  .settings-item-top {
    display: flex;
    font-weight: 500;
    justify-content: space-between;
  }

  .settings-item-actions {
    align-items: center;
    display: flex;
    justify-content: space-between;
    min-width: 90px;
  }

  .settings-item-content {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    .goal-metric {
      background-color: #f4f5f5;
      border: 0;
      border-radius: 0;
      padding: 8px 13px;
      text-align: right;
      width: 90px;
      &:focus {
        outline: 1px solid $blue1;
      }
      @media (prefers-color-scheme: dark) {
        background-color: $grey3-dark;
        color: $white;
      }
      font-size: 16px;
    }

    input:focus {
      outline: 0 transparent;
    }
  }

  .hr-time-in-zone {
    display: flex;
    justify-content: flex-start;
    margin-top: 1em;
    max-width: 91%;
    text-align: left;
  }
}

.android {
  .settings-item-actions {
    min-width: 80px;
  }
}

@media (prefers-color-scheme: dark) {
  .settings-item {
    background-color: $grey6-dark;
  }
}
