.picker-container {
  //z-index: 300;
  // test on phone before removing z-index on this container
  &,
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }
  .inner-container {
    overflow-y: hidden;
    max-height: 215px;
  }
  .picker-inner {
    display: flex;
    font-size: 1.2em;
    justify-content: center;
    padding: 0 20px;
    position: relative;
  }
  .picker-column {
    flex: 1 1;
    overflow: hidden;
    position: relative;
    text-align: center;
    .picker-scroller {
      transition-timing-function: ease-out;
      transition: 100ms;
    }
    .picker-item {
      color: #999999;
      overflow: hidden;
      padding: 0 10px;
      position: relative;
      text-overflow: ellipsis;
      white-space: nowrap;
      &.picker-item-selected {
        color: $blue2;
        font-weight: 600;
        font-size: 1.5em;
      }
    }
  }
  .picker-modal-mask {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    display: none;
    height: 100%;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 1;
    .picker-error {
      background-color: #D32F2F !important;
      border-radius: 4px;
      color: $white;
      display: flex;
      left: 5%;
      padding: .6em;
      position: relative;
      top: 10%;
      width: 90%;
    }
  }
  .picker-modal {
    backface-visibility: hidden;
    background-color: $white;
    bottom: 0;
    border-radius: 8px 8px 0 0;
    font-family: "Inter", sans-serif;
    left: 0;
    position: fixed;
    transform: translate(0, 100%);
    width: 100%;
    z-index: 2;
    display: flex;
    flex-direction: column;
    &.picker-modal-toggle {
      transform: translate(0, 0);
    }
    .picker-top-text {
      background-color: $white;
      border-bottom: 1px solid $grey4;
      border-radius: 8px 8px 0 0;
      display: flex;
      font-weight: 600;
      padding: 1em;
    }
    .picker-days {
      padding: 1em 0;
      display: flex;
      justify-content: center;
      border-bottom: 1px solid $grey4;
      .active {
        color: $white;
        background-color: $blue2;
      }
    }
    .picker-days-button {
      border-radius: 4px;
      border: 1px solid $grey4;
      width: 40%;
      outline: none;
      padding: .5em .75em;
      text-transform: capitalize;
    }
    .picker-actions {
      border-top: 1px solid $grey4;
      display: flex;
      justify-content: flex-end;
      padding: 0.75em 1.25em;
      div, button {
        font-weight: 500;
      }
    }
    .column-labels {
      color: $blue2;
      display: flex;
      font-weight: 600;
      justify-content: space-evenly;
      padding: 1em;
      div {
        width: 33%;
        text-align: center;
      }
    }
    .picker-confirm {
      background-color: $blue2;
      border-radius: 4px;
      border: none;
      color: $white;
      min-width: 80px;
      outline: none;
      padding: 0.5em 0.75em;
      text-transform: capitalize;
    }
    .picker-cancel {
      align-items: center;
      color: $blue2;
      display: flex;
      justify-content: center;
    }
    @media (prefers-color-scheme: dark) {
      background-color: $grey6-dark;
      .picker-top-text {
        background-color: $grey6-dark;
      }
    }
  }
}

.ios {
  .picker-modal {
    margin-bottom: calc(var(--nav-height) - 8px);
  }
}
