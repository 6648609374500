.arrow-line {
  margin: 5px 5% 12px 5%;
  .bottom-arrow {
    border-bottom: 1px solid;
  }
  .bottom-arrow:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 0;
    height: 0;
    border-top: 8px solid;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
  }
}
